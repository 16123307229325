<template>
  <div>
    <v-row justify="center">
      <v-col v-for="(n) in 6" :key="n"  cols="12" md="4">
        <v-lazy
          :options="{
            threshold: 0.75,
            // viel Prozent sichtbar sein müssen, bevor es gerendert wird
          }"
          transition="fade-transition"
        >
          <v-card min-height="150" rounded="lg" hover>
            <v-img
              src="https://www.honig-baustoffe.de/medias/Headbild-Spezialist-Trockenbau-ThinkstockPhotos-523052668.jpg?context=bWFzdGVyfGltYWdlc3wxNTczNTF8aW1hZ2UvanBlZ3xpbWFnZXMvaGMyL2g5ZC84OTc0MDg1MzU3NTk4LmpwZ3w3ZDJhMTliYTliNDAzZDU2YWJjZjM4Y2Y4ODFmNDRkMmQ1NTk4MzFkZWEzYjdjOTJiMzkyNWE2OWRkZWI1MzBm&attachment=true"
            ></v-img>
            <v-card-title class="justify-center">Trockenbau</v-card-title>
            <v-divider style="margin: 0 60px" color="#9C0400" />
            <v-card-text class="text-center">
              <p>
                techem anker sudo pods creative geforce zone shark techem anker
                sudo pods creative geforce zone shark techem anker sudo pods
                creative geforce zone shark
              </p>
            </v-card-text>
          </v-card>
        </v-lazy>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TilesView',
  methods: {},
  data: () => ({}),
};
</script>
