<template>
  <div>
    <v-row justify="center">
      <v-col v-for="(n) in 8" :key="n" cols="12" sm="6" md="3">
        <v-lazy
          :options="{
            threshold: 0.75,
            // viel Prozent sichtbar sein müssen, bevor es gerendert wird
          }"
          transition="fade-transition"
        >
          <v-hover v-slot="{ hover }">
            <v-card rounded="xl" hover>
              <v-img
                src="https://www.honig-baustoffe.de/medias/Headbild-Spezialist-Trockenbau-ThinkstockPhotos-523052668.jpg?context=bWFzdGVyfGltYWdlc3wxNTczNTF8aW1hZ2UvanBlZ3xpbWFnZXMvaGMyL2g5ZC84OTc0MDg1MzU3NTk4LmpwZ3w3ZDJhMTliYTliNDAzZDU2YWJjZjM4Y2Y4ODFmNDRkMmQ1NTk4MzFkZWEzYjdjOTJiMzkyNWE2OWRkZWI1MzBm&attachment=true"
                ><v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transition-fast-in-fast-out
                    \ darken-2 v-card--reveal white--text"
                    style="height: 100%; background-color: rgb(0,0,0,0.65)"
                  >
                    <p style="margin: 20px">
                      techem anker sudo pods creative geforce zone shark techem
                      anker sudo pods creative geforce zone shark techem anker
                      sudo pods creative geforce zone sharktechem anker
                    </p>
                  </div>
                </v-expand-transition>
              </v-img>
              <v-card-title class="justify-center">Trockenbau</v-card-title>
            </v-card>
          </v-hover>
        </v-lazy>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'TilesViewFlip4Details',
  methods: {},
  data: () => ({}),
};
</script>
