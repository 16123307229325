<template>
  <v-container>
    <div class="Services">
      <h1>Hier entsteht die Seite Leistungen</h1>
      <v-select
        v-model="compSwitch"
        :items="compSwitchItems"
        label="Wähle eine TileView Komponente"
        outlined
      ></v-select>
      <v-divider />
      <h2 v-if="compSwitch == 'Tiles View'">Tiles View</h2>
      <TilesView v-if="compSwitch == 'Tiles View'" />
      <h2 v-if="compSwitch == 'Tiles View with hover Flip'">
        Tiles View with hover Flip
      </h2>
      <TilesViewFlip4Details
        v-if="compSwitch == 'Tiles View with hover Flip'"
      />
      <h2 v-if="compSwitch == 'Tiles View Button'">Tiles View Button</h2>
      <TilesOnlyButtonView
        v-if="compSwitch == 'Tiles View Button'"
      />
    </div>
  </v-container>
</template>
<script>
import TilesView from '@/components/TilesView.vue';
import TilesViewFlip4Details from '@/components/TilesViewFlip4Details.vue';
import TilesOnlyButtonView from '@/components/TilesOnlyButtonView.vue';

export default {
  name: 'Services',
  components: {
    TilesView,
    TilesViewFlip4Details,
    TilesOnlyButtonView,
    //
  },
  data: () => ({
    compSwitch: 'Tiles View Button',
    compSwitchItems: [
      'Tiles View',
      'Tiles View with hover Flip',
      'Tiles View Button',
    ],
  }),
};
</script>

<style>
h1 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
}
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.5rem;
}
</style>
